export const NEXT_PUBLIC_APP_DOMAIN_SHARE = process.env.NEXT_PUBLIC_APP_DOMAIN_SHARE
export const NEXT_PUBLIC_GOOGLE_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_API_KEY
export const NEXT_PUBLIC_APP_DOMAIN = process.env.NEXT_PUBLIC_APP_DOMAIN
export const NEXT_PUBLIC_NO_INDEX = process.env.NEXT_PUBLIC_NO_INDEX
export const GTM_KEY = process.env.GTM_KEY
export const NEXT_PUBLIC_ENABLE_LIVE_CHAT = process.env.NEXT_PUBLIC_ENABLE_LIVE_CHAT
export const NEXT_PUBLIC_GOOGLE_APP_ID = process.env.NEXT_PUBLIC_GOOGLE_APP_ID
export const NEXT_PUBLIC_FACEBOOK_APP_ID = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID
export const NEXT_PUBLIC_IFRAME_SRC_FAQ = process.env.NEXT_PUBLIC_IFRAME_SRC_FAQ
export const NEXT_PUBLIC_IFRAME_SRC_FEEBACK = process.env.NEXT_PUBLIC_IFRAME_SRC_FEEBACK
export const NEXT_PUBLIC_IFRAME_SRC_IDEAS = process.env.NEXT_PUBLIC_IFRAME_SRC_IDEAS
export const NEXT_PUBLIC_IFRAME_SRC_REPORTS = process.env.NEXT_PUBLIC_IFRAME_SRC_REPORTS
export const NEXT_PUBLIC_IFRAME_SRC_WHAT_NEWS = process.env.NEXT_PUBLIC_IFRAME_SRC_WHAT_NEWS
export const NEXT_PUBLIC_ENABLE_LIVE_CHAT_USER_TYPE = process.env.NEXT_PUBLIC_ENABLE_LIVE_CHAT_USER_TYPE
export const NEXT_PUBLIC_CRISP_WEBSITE_ID = process.env.NEXT_PUBLIC_CRISP_WEBSITE_ID
export const NEXT_PUBLIC_LINK_JOB_POST_POLICY = process.env.NEXT_PUBLIC_LINK_JOB_POST_POLICY
export const NEXT_PUBLIC_SUPPORT_CONTACT = process.env.NEXT_PUBLIC_SUPPORT_CONTACT
export const NEXT_PUBLIC_GUIDE_TIP = process.env.NEXT_PUBLIC_GUIDE_TIP

// CONFIG
export const NEXT_PUBLIC_SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN
export const NEXT_PUBLIC_APP_ENVIRONMENT = process.env.NEXT_PUBLIC_APP_ENVIRONMENT
export const SENTRY_PROJECT = process.env.SENTRY_PROJECT
export const NEXT_PUBLIC_API_END_POINT = process.env.NEXT_PUBLIC_API_END_POINT
export const NEXT_PUBLIC_ENABLE_API_PROXY = process.env.NEXT_PUBLIC_ENABLE_API_PROXY

export const NEXT_PUBLIC_ENABLE_TRAINING = process.env.NEXT_PUBLIC_ENABLE_TRAINING
export const NEXT_PUBLIC_TRAINING_HOST = process.env.NEXT_PUBLIC_TRAINING_HOST
export const NEXT_PUBLIC_TRAINING_TOKEN = process.env.NEXT_PUBLIC_TRAINING_TOKEN

// MILISEARCH

export const NEXT_PUBLIC_URL_MILISEARCH = process.env.NEXT_PUBLIC_URL_MILISEARCH
export const NEXT_PUBLIC_MILISEARCH_PRIVATE_KEY = process.env.NEXT_PUBLIC_MILISEARCH_PRIVATE_KEY

export const NEXT_PUBLIC_FIREBASE_FCM_PUBLIC_KEY = process.env.NEXT_PUBLIC_FIREBASE_FCM_PUBLIC_KEY

// GLEAP
export const NEXT_PUBLIC_ENABLE_GLEAP = process.env.NEXT_PUBLIC_ENABLE_GLEAP

// NEXT_PUBLIC_METABASE_SECRET_KEY
export const NEXT_PUBLIC_METABASE_SECRET_KEY = process.env.NEXT_PUBLIC_METABASE_SECRET_KEY
export const NEXT_PUBLIC_GLEAP_TOKEN = process.env.NEXT_PUBLIC_GLEAP_TOKEN
